// extracted by mini-css-extract-plugin
export var button = "index-module--button--YtOtN";
export var categories = "index-module--categories--KR0KK";
export var container = "index-module--container--B-MI6";
export var content = "index-module--content--kRclT";
export var controls = "index-module--controls--umO7y";
export var embed = "index-module--embed--VYf7t";
export var image = "index-module--image--t9qt0";
export var img = "index-module--img--MMrOz";
export var imgLink = "index-module--imgLink--xEo7g";
export var label = "index-module--label--hrLVQ";
export var size = "index-module--size--rTfkW";
export var title = "index-module--title--ll3OO";