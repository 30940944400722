import React, { Component } from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import InfographicsPost from "components/infographics/InfographicsPost";
import CTA from "components/shared/CTA";
import Layout from "components/shared/Layout";
import { graphql } from "gatsby";

export default class InfographicTemplate extends Component {
  render() {
    let { pageContext } = this.props;
    const { wpInfographic } = this.props.data;
    const { custom_data, featuredImage } = wpInfographic;

    return (
      <>
        <Meta
          url={pageContext.canonical}
          defaultTitle={custom_data?.metaTitle}
          defaultDescription={custom_data?.metaDescription}
          defaultDeaturedImage={featuredImage?.node?.localFile?.publicURL}
        />
        <Nav />
        <Layout>
          <InfographicsPost wpInfographic={wpInfographic} />{" "}
        </Layout>
        <CTA />
        <Footer />
      </>
    );
  }
}

export const pageQuery = graphql`
  query($id: String!) {
    wpInfographic(id: { eq: $id }) {
      content
      date
      id
      link
      slug
      title
      categories {
        nodes {
          slug
          name
        }
      }
      featuredImage {
        node {
          sourceUrl
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 1100
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      custom_data {
        metaDescription
        metaTitle
      }
    }
  }
`;
