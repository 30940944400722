import React from "react";
import fb from "images/svg_icons/facebook_w.svg";
import twitter from "images/svg_icons/twitter_w.svg";
import linkedin from "images/svg_icons/linkedin_w.svg";
import * as classes from "./index.module.less";

export default ({ title }) => {
  let url = typeof window === "undefined" ? "" : window.location.href;
  return (
    <span className={classes.social}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        className={classes.facebook}
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}&src=sdkpreparse`}
        onClick={(e) => {
          e.preventDefault();
          window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              url
            )}&src=sdkpreparse`,
            "targetWindow",
            `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=400,height=400`
          );
          return false;
        }}
      >
        <img src={fb} alt="Facebook Icon" />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        className={classes.twitter}
        href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
          title
        )} ${url}`}
        onClick={(e) => {
          e.preventDefault();
          window.open(
            `https://twitter.com/intent/tweet?text=${encodeURIComponent(
              title
            )} ${url}`,
            "targetWindow",
            `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=400,height=400`
          );
          return false;
        }}
      >
        <img src={twitter} alt="Twitter Icon" />
      </a>

      <a
        target="_blank"
        rel="noopener noreferrer"
        className={classes.linkedin}
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          url
        )}`}
        onClick={(e) => {
          e.preventDefault();
          window.open(
            `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
              url
            )}`,
            "targetWindow",
            `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=400,height=400`
          );
          return false;
        }}
      >
        <img src={linkedin} alt="Linkedin Icon" />
      </a>
    </span>
  );
};
