import React, { useState } from "react";
import * as classes from "./index.module.less";
import { Link } from "gatsby";
import { decodeEntities } from "src/utils/functions";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Button from "components/shared/Button";
import ShareButtons from "../../shared/ShareButtons";

export default ({ wpInfographic }) => {
  let [width, setWidth] = useState(null);
  let [height, setHeight] = useState(null);
  let textareaRef = React.createRef();

  const { title, content, featuredImage, categories } = wpInfographic;

  let copyText = () => {
    textareaRef.current.select();
    document.execCommand("copy");
    setTimeout(() => alert("Copied to clipboard!"), 500);
  };

  let getHtml = () => {
    const { title, featuredImage } = wpInfographic;
    return featuredImage && featuredImage.node
      ? `<img src="${featuredImage?.node?.sourceUrl}" alt="${decodeEntities(
          title
        )}"${width ? ` width="${width}"` : ""}${
          height ? ` height="${height}"` : ""
        }/>`
      : "";
  };

  let getCategoryTags = (categories) => {
    return categories
      ? categories.map((it, index) => <b key={index}>{it.name}</b>)
      : null;
  };

  let renderImage = () => {
    const image = getImage(featuredImage?.node?.localFile);

    return image ? (
      <GatsbyImage image={image} alt={title} loading="eager" />
    ) : null;
  };

  function removeWww(inputString) {
    // Define a regular expression pattern to match URLs with "www"
    var pattern = /href="https:\/\/www\.appinchina.co\/([a-zA-Z0-9.-]+)\/([^"]+)"/g;
  
    // Use the replace method to remove "www" from the URLs in the inputString
    var modifiedString = inputString.replace(pattern, 'href="https://appinchina.co/$1/$2"');
  
    return modifiedString;
  }

  function addTailSlash(inputString) {
    var pattern = /href="https:\/\/(www\.)?([a-zA-Z0-9.-]+)\/([^"]+)(\/)?"(.*?)"/g;
    var modifiedString = inputString.replace(pattern, 'href="https://$2/$3/"$5"');
    return modifiedString;
  }

  function fixDuplicatedSlash(inputString) {
    var pattern = /href="https:\/\/(www\.)?([a-zA-Z0-9.-]+)\/([^"]+?)(\/+)"(.*?)"/g;
    var modifiedString = inputString.replace(pattern, 'href="https://$2/$3/"$5"');
    return modifiedString;
  }

  function fixContentString(inputString){
    // This function will fix internal links to make sure that all the links are like "https://appinchina.co/som-url/"
    var modifiedString = removeWww(inputString);
    modifiedString = addTailSlash(modifiedString);
    modifiedString = fixDuplicatedSlash(modifiedString);
    modifiedString = modifiedString.replace(/i1.wp.com\//g, "");
    return modifiedString;
  }

  return (
    <>
      <div className={classes.controls}>
        <Link to="/infographics/"> {"<"} View All</Link>
        <span className={classes.categories}>
          <span>Category:</span> {getCategoryTags(categories.nodes)}
        </span>
      </div>
      <h1
        dangerouslySetInnerHTML={{ __html: title }}
        className={classes.title}
      />
      <div className={classes.share}>
        <ShareButtons title={title} />
      </div>
      <div dangerouslySetInnerHTML={{ __html: fixContentString(content) }} />
      <div className={classes.container}>
        <div className={classes.img}>
          {featuredImage && featuredImage.node ? (
            <>
              <a
                className={classes.imgLink}
                href={featuredImage.node.localFile?.publicURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {renderImage()}
              </a>
              <a
                className={classes.button}
                href={featuredImage.node.localFile?.publicURL}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button>Download Image</Button>
              </a>
            </>
          ) : null}
        </div>
        <div className={classes.content}>
          <div className={classes.embed}>
            <h3>Embed Code</h3>
            <div>
              <p className={classes.label}>
                For hosted site and wordpress.com
                <span>Click the code to copy</span>
              </p>
              <textarea
                onClick={() => copyText()}
                value={getHtml()}
                readOnly
                ref={textareaRef}
              ></textarea>
              <div>
                <p className={classes.size}>
                  <span>Customize size</span>
                  <span>
                    <input
                      value={width}
                      onChange={(e) => setWidth(e.target.value)}
                    />
                  </span>
                  <span>x</span>
                  <span>
                    <input
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                    />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
